import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faDollarSign, faCheck } from "@fortawesome/free-solid-svg-icons";
import ApplicationForm from "../form/applicationForm";
import Checkout from "./steps/checkout";
import CheckoutSuccess from "./steps/success";
import "../../../node_modules/bulma-o-steps/bulma-steps.sass";
import "../../styles/styles.scss";

export default ({
  formQuestions,
  formTitle,
  formType,
  submitUrl,
  formOpts,
  currentStepQS,
}) => {
  const [currentStep, setCurrentStep] = useState(currentStepQS || 0);

  const registerDoneCB = () => {
    setCurrentStep(1);
  };

  const getStepStyles = (step) => {
    if (currentStep === step) {
      return "steps-segment  has-gaps is-active";
    } else {
      return "steps-segment has-gaps";
    }
  };

  return (
    <div>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="section">
              <ul className="steps is-narrow is-medium is-centered has-content-centered">
                <li className={getStepStyles(0)}>
                  <a href="#" className="has-text-dark">
                    <span className="steps-marker">
                      <span className="icon">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </span>
                    <div className="steps-content">
                      <p className="heading">Registration</p>
                    </div>
                  </a>
                </li>
                <li className={getStepStyles(1)}>
                  <span className="steps-marker">
                    <span className="icon">
                      <FontAwesomeIcon icon={faDollarSign} />
                    </span>
                  </span>
                  <div className="steps-content">
                    <p className="heading">Payment</p>
                  </div>
                </li>
                <li className={getStepStyles(2)}>
                  <span className="steps-marker is-hollow">
                    <span className="icon">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  </span>
                  <div className="steps-content">
                    <p className="heading">Confirmation</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        {currentStep === 0 && (
          <ApplicationForm
            formQuestions={formQuestions}
            formTitle={formTitle}
            submitUrl={submitUrl}
            formType={formType}
            formOpts={formOpts}
            registerDoneCB={registerDoneCB}
          />
        )}
        {currentStep === 1 && <Checkout />}
        {currentStep === 2 && <CheckoutSuccess />}
      </div>
    </div>
  );
};
