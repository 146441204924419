import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import pricing from "../../../data/pricing";
import "../../../styles/styles.scss";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUB_KEY);

const Checkout = () => {
  const [price, setPrice] = useState(pricing.famRegFee.full);
  const [discountCode, setDiscountCode] = useState("");
  const [error, setError] = useState(false);

  const redirectToCheckout = async (event) => {
    event.preventDefault();
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: price.sku, quantity: 1 }],
      mode: "payment",
      successUrl: process.env.GATSBY_FEE_SUCCESS_URL,
      cancelUrl: process.env.GATSBY_FEE_CANCEL_URL,
    });

    if (error) {
      console.warn("Error:", error);
    }
  };

  const applyDiscount = () => {
    const pLevelKeys = Object.keys(pricing.famRegFee);
    const currentKey = pLevelKeys.find((key) => {
      return pricing.famRegFee[key].discountCode === discountCode;
    });

    if (typeof currentKey !== "undefined") {
      setError(false);
      setPrice(pricing.famRegFee[currentKey]);
    } else {
      setError(true);
    }
  };

  const handleChange = (evt) => {
    setDiscountCode(evt.target.value);
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column"></div>
        <div className="column is-6">
          <div className="box">
            <div className="container">
              <div>
                Thank you for completing the registration form! <br></br>
                <br></br> The final step is to pay the new family registration
                fee. By clicking the button below, you will be redirected to
                Stripe to complete the transaction.
              </div>
              <br></br>
              <div className="field">
                <label className="label">Have a discount code?</label>
                <div className="field has-addons">
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Enter Code Here"
                      value={discountCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="control">
                    <a onClick={applyDiscount} className="button is-info">
                      Apply Discount
                    </a>
                  </div>
                </div>
                {error && (
                  <p className="help is-danger">Invalid Discount Code</p>
                )}
              </div>
              <br />
              <div className="columns">
                <div className="column is-8 has-text-centered has-vertically-aligned-content">
                  New Family Registration Fee:{" "}
                </div>
                <div className="column is-4 is-size-4 has-text-centered has-vertically-aligned-content">
                  ${price.amount}
                </div>
              </div>
              <div className="container has-text-centered">
                <button className="button is-link" onClick={redirectToCheckout}>
                  Pay With Stripe Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="column"></div>
      </div>
    </div>
  );
};

export default Checkout;
