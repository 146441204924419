export default {
    famRegFee: {
        full: {
            amount: '195.00',
            sku: process.env.GATSBY_FAM_REG_FEE_SKU,
            discountCode: null
        },
        discount5: {
            amount: '185.25',
            sku: process.env.GATSBY_FAM_REG_FEE_DISCOUNT5_SKU,
            discountCode: 'MBN5'
        },
        discount10: {
            amount: '175.50',
            sku: process.env.GATSBY_FAM_REG_FEE_DISCOUNT10_SKU,
            discountCode: 'MBN10'
        },
        discount15: {
            amount: '165.75',
            sku: process.env.GATSBY_FAM_REG_FEE_DISCOUNT15_SKU,
            discountCode: 'MBN15'
        }
    }
}
