// This is an example of each type of field.  Add your updates below this section.
export default [
  {
    name: "contact",
    label: "Contact Info",
    questions: [
      // Text input example
      {
        name: "firstName", // A unique identifier, keep it simple :)
        label: "First Name", // The main label above the input field.
        type: "input", // See types above ['input', 'textArea', 'select', 'checkbox', or 'radio']
        subType: "text", // ['text', 'password', 'email', or 'tel']
        required: false, // If it is required [true or false]
        placeholder: "", // A hint if needed
      },
      // Text area example
      {
        name: "textAreaExample",
        label: "Enter in the text area.",
        type: "textArea",
        required: false,
        placeholder: "tips",
        rows: 10, // The number of rows for the text area
      },
    ],
  },
  {
    name: "background",
    label: "Background Information",
    questions: [
      // Dropdown/select field example
      {
        name: "country",
        label: "What country are you from?",
        type: "dropdown",
        required: false,
        options: ["USA", "Canada"], // This list of options for the dropdown.
      },
      // Checkbox example
      {
        name: "agreeTerms", // a unique identifier
        label: "Do you agree to the terms?",
        type: "checkbox", // the type of field
      },
      // Radio button example
      {
        name: "radioExample", // a unique identifier
        label: "Please select one.",
        type: "radio", // the type of field
        options: ["one", "two", "three"], // Options for radio buttons
        // checked: "three", // Pre check a box, if desired.
        required: false,
      },
      // Multiselect example
      {
        name: "multiselectExample", // a unique identifier
        label: "Please select all that apply.",
        type: "multiselect", // the type of field
        options: ["option 1", "option 2", "option 3"], // Options for radio buttons
      },
      {
        name: "city",
        label: "City",
        type: "input",
        required: false,
      },
      {
        name: "state",
        label: "State",
        type: "state",
        required: false,
      },
      {
        name: "zip",
        label: "Zip Code",
        type: "input",
        required: false,
      },
    ],
  },
];
