import React from "react";
import "../../../styles/styles.scss";

export default () => {
  return (
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-6">
            <div className="box">
              <div className="container">
                <div className="title has-text-centered">
                  Thank you for registering!
                </div>
                <div className="subtitle has-text-centered">
                  We will be getting in touch with you soon.
                </div>
              </div>
            </div>
          </div>
          <div className="column"></div>
        </div>
      </div>
  );
};
