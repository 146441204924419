import React from "react";
import Layout from "@components/layout/layout";
import formQuestions from "@data/questions/exampleFormQuestions";
import Stepper from "@components/register/registerPayStepper";
import queryString from 'query-string';
import "@styles/styles.scss";

export default (props) => {
  const formOpts = {
    userPhotoLabel: "Please provide a photo.",
    submitButtonLabel: "Submit and Proceed to Payment",
  };
  const formType = "Test";
  const formTitle = "Test Family Registration Form";

  const qs = queryString.parse(props.location.search);
  let currentStepQS;
  if (typeof qs.step !== 'undefined') {
    try {
      currentStepQS = JSON.parse(qs.step);
    } catch (error) {
      
    }
  }

  return (
    <Layout pageTitle="Apply">
      <div style={{ height: "15vh" }}></div>
      <Stepper
        formQuestions={formQuestions}
        formTitle={formTitle}
        formType={formType}
        formOpts={formOpts}
        currentStepQS={currentStepQS}
      />
    </Layout>
  );
};