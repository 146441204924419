import React from "react";

import "../../styles/styles.scss";

const TextField = ({
  name,
  placeholder,
  // subType,
  label,
  required,
  register,
  isSubmitting,
  errors,
}) => {
  const error = errors[name];
  const styles = error ? "input is-danger" : "input";

  return (
    // todo subtype

    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <input
          placeholder={placeholder || ""}
          className={styles}
          type="text"
          name={name}
          ref={required ? register({ required }) : register()}
          disabled={isSubmitting}
        ></input>
      </div>
      {error && error.type === "required" && (
        <p className="help is-danger">This field is required.</p>
      )}
    </div>
  );
};

const TextArea = ({
  name,
  placeholder,
  rows,
  label,
  required,
  register,
  isSubmitting,
  errors,
}) => {
  const nRows = typeof rows === undefined ? "10" : `${rows}`;
  const error = errors[name];
  const styles = error ? "textarea is-danger" : "textarea";
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <textarea
          placeholder={placeholder || ""}
          className={styles}
          rows={nRows}
          name={name}
          ref={required ? register({ required }) : register()}
          disabled={isSubmitting}
        ></textarea>
      </div>
      {error && error.type === "required" && (
        <p className="help is-danger">This field is required.</p>
      )}
    </div>
  );
};

const DropDown = ({
  name,
  label,
  options,
  required,
  register,
  isSubmitting,
  errors,
}) => {
  const error = errors[name];
  const styles = error ? "select is-danger" : "select";
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className={styles}>
        <select
          name={name}
          ref={required ? register({ required }) : register()}
          disabled={isSubmitting}
        >
          {options.map((opt) => {
            return <option key={opt}>{opt}</option>;
          })}
        </select>
      </div>
      {error && error.type === "required" && (
        <p className="help is-danger">This field is required.</p>
      )}
    </div>
  );
};

const CheckBox = ({ name, label, register, isSubmitting }) => {
  return (
    <div className="field">
      {/* <div className="control"> */}
      <input
        className="is-checkradio"
        id={name}
        type="checkbox"
        name={name}
        // value={name}
        ref={register()}
        disabled={isSubmitting}
      />
      <label htmlFor={name}>{label}</label>
      {/* <label className="checkbox">
          <input
            type="checkbox"
            name={name}
            ref={register()}
            disabled={isSubmitting}
          />
          {` ${label}`} */}
      {/* </label> */}
      {/* </div> */}
    </div>
  );
};

const Radio = ({
  name,
  options,
  label,
  checked,
  register,
  required,
  isSubmitting,
  errors,
}) => {
  const error = errors[name];
  const styles = error ? "is-checkradio is-danger" : "is-checkradio";
  return (
    <div className="field">
      <label className="label">{label}</label>
      {options.map((opt) => {
        return (
          <div key={opt}>
            <input
              type="radio"
              // className="is-checkradio"
              disabled={isSubmitting}
              ref={required ? register({ required }) : register()}
              name={name}
              // id={opt}
              value={opt}
              defaultChecked={checked === opt}
            />
            <label htmlFor={opt}> {opt}</label>
          </div>
        );
      })}
      {error && error.type === "required" && (
        <p className="help is-danger">This field is required.</p>
      )}
    </div>
  );
};

const MultiSelect = ({ name, options, label, register, isSubmitting }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      {options.map((opt) => {
        return (
          <div key={opt}>
            <input
              className="is-checkradio"
              id={opt}
              type="checkbox"
              name={name}
              value={opt}
              ref={register()}
              disabled={isSubmitting}
            />
            <label htmlFor={opt}>{opt}</label>
          </div>
        );
      })}
    </div>
  );
};

const StateDropDown = ({
  name,
  label,
  options,
  required,
  register,
  isSubmitting,
  errors,
}) => {
  const error = errors[name];
  const styles = error ? "select is-danger" : "select";
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className={styles}>
        <select
          name={name}
          defaultValue={'CO'}
          ref={required ? register({ required }) : register()}
          disabled={isSubmitting}
        >
          {/* {options.map((opt) => {
            return <option key={opt}>{opt}</option>;
          })} */}
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>
      {error && error.type === "required" && (
        <p className="help is-danger">This field is required.</p>
      )}
    </div>
  );
};

export {
  TextField,
  TextArea,
  DropDown,
  CheckBox,
  Radio,
  MultiSelect,
  StateDropDown,
};
