import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  TextArea,
  DropDown,
  CheckBox,
  Radio,
  MultiSelect,
  StateDropDown,
} from "./formElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import "../../styles/styles.scss";

export default ({
  formQuestions,
  formTitle,
  formType,
  formOpts,
  registerDoneCB,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [photoFile, setPhotoFile] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    const newData = {};
    Object.keys(data).forEach((key) => {
      let label;
      formQuestions.forEach((section) => {
        if (typeof label === "undefined") {
          label = section.questions.find((q) => {
            return q.name === key;
          });
        }
      });

      newData[key] = {
        label: label ? label.label : key,
        value: data[key],
      };
    });
    console.log(newData);

    // setSubmitted(true);
    // reset();
    const formData = new FormData();
    if (photoFile) {
      if (photoFile.size > 4000000) {
        setError(
          "submit",
          "submitError",
          `Please select a photo that is smaller than 4mb or proceed without a photo.`
        );
        setPhotoFile(false);
        return;
      } else {
        formData.append("userPhoto", photoFile, photoFile.name);
      }
    }
    // console.log(formOpts.familyID);
    // formData.append("familyID", formOpts.familyID);
    formData.append(
      "userResponses",
      JSON.stringify({
        formType,
        familyID: formOpts.familyID,
        responses: { ...newData },
      })
    );

    try {
      await fetch(process.env.GATSBY_FORM_SUBMIT_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: formData,
      });

      if (registerDoneCB) {
        registerDoneCB();
      } else {
        setSubmitted(true);
        reset();
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! Please contact us and we'll work to resolve it.`
      );
    }
  };

  const showSubmitError = (msg) => <p className="has-text-danger">{msg}</p>;

  const showThankYou = (
    <div className="container has-text-centered">
      <p className="subtitle">Thank you for applying!</p>
      <p className="">We will review your answers and e-mail you, soon!</p>
      <br />
      <button className="button is-link" onClick={() => setSubmitted(false)}>
        Return to the application form
      </button>
    </div>
  );

  const handleFileInputChange = (e) => {
    // e.persist();
    if (e.target.files.length > 1) {
      // todo set error
      // Please select only one file.
    }
    setPhotoFile(e.target.files[0]);
  };

  const showDynamicForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formQuestions.map((section) => {
        return (
          <div key={section.name}>
            <br></br>
            <div className="is-divider" data-content={section.label}></div>
            {section.questions.map((form) => {
              const label = form.required ? `${form.label}*` : form.label;
              // TextField
              if (form.type === "input") {
                return (
                  <TextField
                    name={form.name}
                    placeholder={form.placeholder}
                    subType={form.subType}
                    label={label}
                    required={form.required}
                    register={register}
                    isSubmitting={isSubmitting}
                    key={form.name}
                    errors={errors}
                  />
                );
              }

              // TextArea
              else if (form.type === "textArea") {
                return (
                  <TextArea
                    name={form.name}
                    placeholder={form.placeholder}
                    rows={form.rows}
                    label={label}
                    required={form.required}
                    register={register}
                    isSubmitting={isSubmitting}
                    key={form.name}
                    errors={errors}
                  />
                );
              }

              // DropDown
              else if (form.type === "dropdown") {
                return (
                  <DropDown
                    name={form.name}
                    options={form.options}
                    label={label}
                    required={form.required}
                    register={register}
                    isSubmitting={isSubmitting}
                    key={form.name}
                    errors={errors}
                  />
                );
              }

              // StateDropDown
              else if (form.type === "state") {
                return (
                  <StateDropDown
                    name={form.name}
                    options={form.options}
                    label={label}
                    required={form.required}
                    register={register}
                    isSubmitting={isSubmitting}
                    key={form.name}
                    errors={errors}
                  />
                );
              }

              // CheckBox
              else if (form.type === "checkbox") {
                return (
                  <CheckBox
                    name={form.name}
                    label={label}
                    register={register}
                    isSubmitting={isSubmitting}
                    key={form.name}
                  />
                );
              }

              // Radio
              else if (form.type === "radio") {
                return (
                  <Radio
                    name={form.name}
                    options={form.options}
                    label={label}
                    register={register}
                    required={form.required}
                    checked={form.checked}
                    isSubmitting={isSubmitting}
                    key={form.name}
                    errors={errors}
                  />
                );
              }
              // MultiSelect
              else if (form.type === "multiselect") {
                return (
                  <MultiSelect
                    name={form.name}
                    options={form.options}
                    label={label}
                    register={register}
                    isSubmitting={isSubmitting}
                    key={form.name}
                  />
                );
              } else {
                return <div key={form}></div>;
              }
            })}
          </div>
        );
      })}
      <br />
      <label>
        {formOpts.userPhotoLabel ||
          "Please provide a current photo of yourself."}
      </label>
      <br></br>
      <div className="file has-name">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            accept="image/*"
            name="photoFile"
            onChange={handleFileInputChange}
          />
          <span className="file-cta">
            <span className="file-icon">
              <FontAwesomeIcon icon={faUpload} />
            </span>
            <span className="file-label">Choose a file…</span>
          </span>
          <span className="file-name">{photoFile.name}</span>
        </label>
      </div>
      <br></br>
      <div className="field is-grouped">
        <div className="control">
          <button
            className="button is-link"
            type="submit"
            disabled={isSubmitting}
          >
            {formOpts.submitButtonLabel || "Submit"}
          </button>
        </div>
      </div>
    </form>
  );

  const showForm = (
    <div>
      <h1 className="title">{formTitle}</h1>
      <p className="subtitle">Please fill out and submit the form below.</p>
      {showDynamicForm()}
    </div>
  );

  return (
    <React.Fragment>
      <div className="columns">
        <div className="column"></div>
        <div className="column is-10">
          <div className="box">
            <div>{submitted ? showThankYou : showForm}</div>
            {errors && errors.submit && showSubmitError(errors.submit.message)}
          </div>
        </div>
        <div className="column"></div>
      </div>
    </React.Fragment>
  );
};
